import { createRouter, createWebHistory } from 'vue-router';
import UserRegister from '../components/UserRegister.vue';
import UserLogin from '../components/UserLogin.vue';
import DataDisplay from '../components/DataDisplay.vue';
import AdminUpload from '../components/AdminUpload.vue';

// Other imports...

function isLoggedIn() {
  return !!localStorage.getItem('token');
}

const routes =  [
    {
      path: '/login',
      alias: '/',
      name: 'Login',
      component: UserLogin
    },
    {
      path: '/register',
      name: 'Register',
      component: UserRegister
    },
    {
      path: '/data',
      name: 'DataDisplay',
      component: DataDisplay,
      meta: { requiresAuth: true } 
    },
    {
      path: '/admin',
      name: 'AdminUpload',
      component: AdminUpload,
      meta: { requiresAuth: true }
    }
  ];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation guard to protect routes
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !isLoggedIn()) {
    // Redirect to login page if not logged in and trying to access a protected route
    next({ name: 'Login' });
  } else {
    // Proceed to the route if either logged in or the route does not require authentication
    next();
  }
});

export default router;
