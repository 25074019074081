<template>
  
  <div id="app">
    <nav>
      <div class="logo-wrap">
      <img class="logo" alt="Matis logo" :src="require('@/assets/logo.png')">
      </div>
      <div class="navbar">
      <router-link v-if="!isLoggedIn" @login-success="updateLoginState" to="/login">Innskrá</router-link>
      <!--<router-link to="/register">Register</router-link>-->
      <router-link v-if="isLoggedIn" to="/data">Mælingar</router-link>
      <router-link v-if="isAdmin && isLoggedIn" to="/admin">Flytja inn skrár</router-link>
      <p class="clientname">{{ clientName }}</p>
      <button v-if="isLoggedIn" id="logout-btn" @click="logout">Útskrá</button>
      </div>
    </nav>
    <router-view/>
  </div>
</template>

<script>
import { EventBus } from '/eventBus';
export default {
  name: 'App',
  data() {
    return {
      // Define clientName with a default value, you can update this value later as needed
      clientName: localStorage.getItem('clientName') || '',
      isLoggedIn: !!localStorage.getItem('token'), // Boolean value to check if user is logged in
      isAdmin: localStorage.getItem('role') === 'admin'
    };
  },
  methods: {
    checkLoginStatus() {
    this.isLoggedIn = !!localStorage.getItem('token');
    this.isAdmin = localStorage.getItem('role') === 'admin';
    this.clientName = localStorage.getItem('clientName') || '';
  },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('clientName');
      this.isLoggedIn = false;
      this.clientName = '';
      this.$router.push('/login');
    },
    updateLoginState(data) {
      this.isLoggedIn = data.isLoggedIn;
      if (data.isLoggedIn) {
        // Optionally update clientName or other state here
        this.clientName = localStorage.getItem('clientName') || '';
        this.isAdmin = localStorage.getItem('role') === 'admin';
      }
    }
  },
  beforeUnmount() { // Updated to use beforeUnmount instead of beforeDestroy
    EventBus.off('login-success', this.updateLoginState); // Unsubscribe from the event
  },
  mounted() {
    this.checkLoginStatus();
    EventBus.on('login-success', this.updateLoginState); // Subscribe to the event
  },
  created() {
    EventBus.on('login-success', this.updateLoginState); // Subscribe to the event
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

input, textarea, select, a { outline: none; }

.clientname {
  padding-right: 15px;
  padding-left: 30px;
  font-weight: 700;
  border-left: 1px solid black;
  margin-left: 20px;
  color: #0139FD;
}

#logout-btn {
  border: none;
  background: #FFBE3C;
  padding: 10px;
  cursor: pointer;
  font-weight: 700;
  width: 100px;
}

#logout-btn:hover {
  background-attachment:#7cafed;
;
}

body {
  margin: 0px;
}

.logo-wrap {
  padding-left: 50px;
}

nav {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.navbar {
  width: 80%;
  text-align: right;
  padding: 27px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbar a {
  font-family: figtree;
  text-decoration: none;
  color: #201B66;
  margin-right: 15px;
  padding-top: 2px;
  padding-bottom: 3px;
}

.logo {
  height: 40px;
}

@media only screen and (max-width: 800px) {
  .navbar {
    width: 100%;
    padding: 0px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  nav {
    display: block;
  }

  #logout-btn {
    width: 70px;
    background-color: #FFBE3C;
  }

  .clientname {
  padding-right: 15px;
  padding-left: 15px;
  font-weight: 700;
  border-left: 1px solid black;
  margin-left: 0px;
}

.logo {
  height: 30px;
}

  .logo-wrap {
    padding-left: 0px;
    padding-top: 20px;
    width: 100%;
  }
}
</style>
