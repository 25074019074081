<template>
  <div class="mainimg-wrap">
      <img class="mainimg" :src="require('@/assets/dropi.png')"/>
  </div>
  <h2 class="header-text">Mæliþjónusta Matís</h2>
  <div class="login-container">
    <form @submit.prevent="login">
      <div>
        <input placeholder="Notendanafn" type="text" id="username" v-model="username" required>
      </div>
      <div>
        <input placeholder="lykilorð" type="password" id="password" v-model="password" required>
      </div>
      <button class="login-btn" type="submit">Innskrá</button>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      <div v-if="isLoading" class="loading-message"><p>Sæki gögn...</p></div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import { EventBus } from '/eventBus';

export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
      isLoading: false, 
    };
  },
  methods: {
    async login() {
      this.errorMessage = '';
      this.isLoading = true;
      try {
        const response = await axios.post('https://maelingar.matis.is/api/login', {
          username: this.username,
          password: this.password
        });
        if (response && response.data) {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('clientName', response.data.clientName);
          localStorage.setItem('clientId', response.data.clientId);
          localStorage.setItem('role', response.data.role);
          //this.$emit('login-success');
          EventBus.emit('login-success', { isLoggedIn: true });
          if (response.data.clientId) {
            console.log('ClientId:', response.data.clientId);
          }
          this.$router.push('/data');
         } else {     
          console.error('No response data');
        }
        this.isLoading = false; 
      } catch (error) {
        this.errorMessage = 'Notandanafn eða lykilorð er rangt.'; // Set error message
      }
    }
  }
};
</script>

<style>
input {
  font-family: figtree;
  margin: 10px;
  padding: 7px;
  width: 200px;
}

.login-container {
  margin-top: 60px;
}

.login-btn {
  font-family: figtree;
  margin: 10px;
  padding: 12px;
  background: #FF7E00;
  cursor: pointer;
  border: none;
  width: 220px;
  color: white;
  font-weight: 700;
}

.mainimg {
  height: 100px;
}

.mainimg-wrap {
  margin-top: 60px; 
}

.header-text {
  margin-top: 20px;
  margin-bottom: 0px;
}
</style>