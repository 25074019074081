<template>
    <div class="admin-upload">
      <font-awesome-icon :icon="['fas', 'cloud-arrow-up']" />
      <input type="file" @change="handleFileUpload" accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
      <button  class="upload-btn" @click="uploadFile">Flytja inn skrá</button>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import * as XLSX from 'xlsx';

  export default {
    data() {
      return {
        selectedFile: null,
      };
    },
    methods: {
      async handleFileUpload(event) {
          const file = event.target.files[0];
          const isValid = await this.validateFile(file);
            if (isValid) {
              this.selectedFile = file;
            } else {
              this.selectedFile = null;
            }
          },
        async uploadFile() {
          if (!this.selectedFile) {
            alert("Please select a file first.");
            return;
        }
        const formData = new FormData();
        formData.append("file", this.selectedFile); // Ensure 'file' matches the name expected by your server

        try {
          await axios.post("https://maelingar.matis.is/api/upload", formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          alert("File uploaded successfully.");
        } catch (error) {
          console.error("Upload failed:", error);
          alert("Failed to upload file.");
        }
      },
      validateFile(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
            console.log(headers);
            const expectedHeaders = ['ClientId', 'ProjectNumber', 'SampleDesc', 'DrawNumber', 'SampleDate', 'Analyte', 'Final', 'Type', 'SampleTypeProduct', 'Units'];
            console.log(expectedHeaders);
            const isValid = expectedHeaders.every((header, index) => headers[index] === header);
            if (!isValid) {
              alert('Incorrect format.');
              resolve(false);
            } else {
              resolve(true);
            }
          };
          reader.onerror = (error) => reject(error);
          reader.readAsBinaryString(file);
        });
      },
    }
  };
  </script>

<style>

.admin-upload {
  margin-top: 60px;
}

.upload-btn {
  border: none;
  background: #ABD1FE;
  padding: 10px;
  cursor: pointer;
  width: 150px;
}

.upload-inp {
  border: none;
  background: #FFBE3C;
  padding: 10px;
  cursor: pointer;
  width: 150px;
}

</style>