<template>
  <div id="print-section">
    <h2 class="main-text">{{clientName}} | Yfirlit mælinga</h2>
    <div class="filter-row filter-row-buttons">
      <div class="date-wrap">
        
    <label for="startd">Frá:</label>
    <input id="startd" type="date" v-model="startDate" @change="fetchData" />
  
    <label for="enddate" class="til">Til:</label>
    <input id="enddate" type="date" v-model="endDate" @change="fetchData" />

      </div>
    <button id="excel-btn" @click="downloadExcel">Sækja Excel skrá</button>
    <button id="print-btn" @click="printData">Prenta</button>
    </div>
    <div class="table-filter">
      <div class="rowspp-wrap">
        <div class="rowspp-textwrap">
        <p>Sýna</p>
      <select class="rowspp" v-model="rowsPerPage">
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
        <p>færslur</p>
        </div>
        <div class="leit-wrap">
        <input class="leit" v-model="searchQuery" @input="fetchData" placeholder="Sláðu inn leitarorð">
        </div>
      </div>
    <div class="table-wrap"> 
    <table>
      <thead>
        <tr>
          <!--<th>Client ID</th>-->
          <th @click="sortTable('ProjectNumber')">ProjectNumber</th>
          <th @click="sortTable('SampleDesc')">SampleDesc</th>
          <th @click="sortTable('DrawNumber')">DrawNumber</th>
          <th @click="sortTable('Type')">Type</th>
          <th @click="sortTable('SampleTypeProduct')">SampleTypeProduct</th>
          <th @click="sortTable('SampleDate')">SampleDate</th>
          <th @click="sortTable('Analyte')">Analyte</th>
          <th @click="sortTable('Final')">Final</th>
          <th @click="sortTable('Units')">Units</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item._id">
          <!--<td>{{ item.ClientId }}</td>-->
          <td>{{ item.ProjectNumber }}</td>
          <td>{{ item.SampleDesc }}</td>
          <td>{{ item.DrawNumber }}</td>
          <td>{{ item.Type }}</td>
          <td>{{ item.SampleTypeProduct }}</td>
          <td>{{ formatDate(item.SampleDate) }}</td>
          <td>{{ item.Analyte }}</td>
          <td>{{ item.Final }}</td>
          <td>{{ item.Units }}</td>
        </tr>
      </tbody>
    </table>
    </div> 
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      items: [],
      rowsPerPage: 20,
      searchQuery: '',
      startDate: '',
      endDate: '',
      currentSortField: 'SampleDate',
      currentSortOrder: 'desc',
      clientName: ''
    };
  },
  methods: {
    sortTable(field) {
      // If the field is the same as the current field, toggle the sort order
      if (this.currentSortField === field) {
        this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        // If it's a different field, start with ascending order
        this.currentSortField = field;
        this.currentSortOrder = 'asc';
      }
      // Now fetch the sorted data
      this.fetchData();
    },
    async downloadExcel() {
      try {
        const response = await axios.get('https://maelingar.matis.is/api/data/excel', {
          params: {
            search: this.searchQuery,
            startDate: this.startDate,
            endDate: this.endDate,
            sortBy: this.currentSortField,
            order: this.currentSortOrder,
            limit: this.rowsPerPage,
            // Include any additional parameters used for filtering and display
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          responseType: 'blob',
        });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data.xlsx'); // File name
            document.body.appendChild(link);
            link.click();
            link.remove();
          } catch (error) {
            console.error('Error downloading Excel:', error);
            // Handle errors, such as prompting for login if 401 is returned
          }
        },
    async fetchData() {
      try {
        const response = await axios.get(`https://maelingar.matis.is/api/data?limit=${this.rowsPerPage}`, {
          params: {
            search: this.searchQuery, // Include the searchTerm as a query parameter
            startDate: this.startDate,
            endDate: this.endDate,
            sortBy: this.currentSortField,
            order: this.currentSortOrder
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        this.items = response.data;
      } catch (error) {
        console.error('There was an error fetching the data:', error);
        if (error.response && error.response.status === 401) {
          this.$router.push('/login');
        }
      }
    },
    printData() {
      let printContents = document.getElementById('print-section').innerHTML;
      let originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents; // Replace the body contents with the print section contents
      window.print(); // Call the browser's print function

      document.body.innerHTML = originalContents; // Restore the original contents after printing
    },
    formatDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleString('en-GB', options).replace(',', '');
    }
  },
  created() {
    this.fetchData(); // Call fetchData when the component is created to load initial data
    this.clientName = localStorage.getItem('clientName') || '';
  },
  watch: {
    searchTerm: {
      handler() {
        this.fetchData(); // Refetch data when searchTerm changes
      },
      immediate: true, // This ensures fetchData is called immediately when the component loads
    },
    rowsPerPage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchData();
      }
    }
  }
};
</script>

<style>
.table-wrap {
  overflow-y: scroll;
}

.main-text {
  margin-top: 60px;
}

.rowspp-textwrap {
  display: inline-flex;
  margin-left: 15px;
  font-weight: 700;
  color: #201B66;
}

.rowspp-wrap {
  width: 100%;
  display: inline-flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.rowspp {
    border: none;
    font-family: figtree;
    margin-left: 6px;
    margin-right: 6px;
    font-weight: 700;
    color: #0139FD;
    font-size: 16px;
}

.filter-row-buttons {
  padding-bottom: 20px;
}

label {
  font-size: 14px;
}

.til {
  margin-right: 5px;
}

.leit {
  font-family: figtree;
  width: 150px;
  font-size: 14px;;
}

.leit-wrap {
  text-align: right;
  width: 50%;
}

.filter-row-buttons button, .filter-row-buttons input, .leit {
  margin: 10px;
  padding: 7px;
}

#excel-btn {
  border: none;
  background: #3CFFA0;
  padding: 10px;
  cursor: pointer;
  width: 150px;
}

#print-btn {
  border: none;
  background: #ABD1FE;
  padding: 10px;
  cursor: pointer;
  width: 150px;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  overflow-x: scroll;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

th {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  background-color: #E6E6E6;
  cursor: pointer;
}
</style>
