<template>
    <div>
      <form @submit.prevent="register">
        <input type="text" v-model="username" placeholder="Username" required>
        <input type="password" v-model="password" placeholder="Password" required>
        <button type="submit">Register</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        username: '',
        password: ''
      };
    },
    methods: {
      async register() {
        try {
          await axios.post('https://maelingar.matis.is/api/register', {
            username: this.username,
            password: this.password
          });
          // Handle success, redirect or inform the user
        } catch (error) {
          // Handle error, show message to the user
        }
      }
    }
  };
  </script>
  